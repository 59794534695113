import { size } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import AvatarImage from '../assets/avatar.jpg'
import { emUnit } from '../theme/helpers/grid'

const StyledAvatar = styled.img`
  border-radius: 50%;
  box-sizing: content-box;
  display: block;
  ${size(emUnit(8))}
`

export const Avatar: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (
  props,
) => <StyledAvatar src={AvatarImage} {...props} />
