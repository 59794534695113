import styled, { css, keyframes } from 'styled-components'

import { getColor } from '../theme/selectors'

interface MainProps {
  animateBackground?: boolean
}

const backgroundAnimation = (from: string, to: string) => keyframes`
  0% {
    background-color: ${from};
  }
  100% {
    background-color: ${to};
  }
`

export const Main = styled.main<MainProps>`
  background-color: ${getColor('backgroundPrimary')};
  animation: ${(props) =>
    props.animateBackground &&
    css`
      ${backgroundAnimation(
        '#F2F5FA',
        props.theme.colors.backgroundPrimary,
      )} 1.5s ease-in-out backwards
    `};
  z-index: 1;
`
