import { format } from 'date-fns'
import { frCA } from 'date-fns/locale'
import * as React from 'react'
import styled from 'styled-components'

import { configureFontSize } from '../../../theme/helpers/typography'
import { getColor } from '../../../theme/selectors'

interface PublicationDateProps {
  children: Date
}

const StyledDate = styled.h4`
  color: ${getColor('primary')};
  ${configureFontSize(10, 12)}
`

export function PublicationDate({ children: date }: PublicationDateProps) {
  return (
    <StyledDate>{format(date, 'd MMMM yyyy', { locale: frCA })}</StyledDate>
  )
}
