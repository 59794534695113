import { em, fluidRange, margin } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { PropsWithTheme } from '../../../../types/theme'
import { emUnit, percentCol } from '../../../theme/helpers/grid'
import {
  getBreakpoint,
  getColorPalette,
  mediaQuery,
} from '../../../theme/selectors'
import { PostImage, PostImageProps, StyledGatsbyImage } from '../../PostImage'

const StyledImage = styled(StyledGatsbyImage)`
  background-color: ${getColorPalette('green', 'lighter')};
  ${margin(null, percentCol(-3))}

  ${mediaQuery('small')`
    ${margin(null, percentCol(-4))}
  `}

  ${mediaQuery('medium')`
  ${margin(null, percentCol(-3))}
    ${(props: PropsWithTheme) =>
      fluidRange(
        {
          prop: 'border-radius',
          fromSize: emUnit(3),
          toSize: emUnit(6),
        },
        `${em(getBreakpoint('medium')(props))}`,
        `${em(getBreakpoint('veryLarge')(props))}`,
      )}
  `}
  ${mediaQuery('large')`
    ${margin(null, percentCol(-4))}
  `}
`

export const Image: React.FC<Pick<PostImageProps, 'image'>> = ({ image }) => (
  <PostImage image={image}>
    {(imageProps) => <StyledImage {...imageProps} />}
  </PostImage>
)
