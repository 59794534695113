import GatsbyImage from 'gatsby-image'
import * as React from 'react'
import styled, { useTheme } from 'styled-components'

import * as Types from '../../../types'
import { Theme } from '../../../types/theme'

type FluidImage = Required<Types.Post['image']['fluid']>
type PostImageType = 'default' | 'feature' | 'poster'
export interface PostImageProps {
  image: Types.Post['image']
  type?: PostImageType
  children({ fluid }: { fluid: FluidImage }): JSX.Element
}

export const StyledGatsbyImage = styled(GatsbyImage)`
  picture {
    position: static;
  }
`

const getPostImageRatio = (
  type: PostImageType,
  ratios: Theme['imageRatios'],
) => {
  switch (type) {
    case 'feature':
      return ratios.postFeatureImage
    case 'poster':
      return ratios.postPosterImage
    default:
      return ratios.postImage
  }
}

export const PostImage: React.FC<PostImageProps> = function ({
  children,
  image: { fluid, fixed, ...restProps },
  type = 'default',
}) {
  const theme = useTheme()

  const fluidProps = {
    ...fluid,
    aspectRatio: getPostImageRatio(type, theme.imageRatios),
  } as FluidImage

  return children({ fluid: fluidProps, ...restProps })
}
