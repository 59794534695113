import Link from 'gatsby-link'
import { padding, timingFunctions } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { PostExcerpt } from '../../../types'
import { emUnit, toUnit } from '../../theme/helpers/grid'
import { configureFontSize } from '../../theme/helpers/typography'
import { getColor, getColorPalette } from '../../theme/selectors'
import { PostImage, StyledGatsbyImage } from '../PostImage'
import { PostMetadata as Metadata } from '../PostMetadata'
import { HeadingProps } from '../text/Heading'
import { WidowlessTitle } from '../text/WidowlessTitle'

export interface VerticalPostPropsFeature {
  className?: string
  featured?: boolean
  post: PostExcerpt
}

const imageHeight = emUnit(31)

const Image = styled(StyledGatsbyImage)`
  height: 100%;
  transition: 0.25s ${timingFunctions('easeOutBack')};
`

const ImageLink = styled(Link)`
  position: absolute;
`

const Content = styled.div`
  ${padding(emUnit(2), emUnit(6), emUnit(9), emUnit(3))}
`

const StyledTitle = styled(WidowlessTitle)`
  color: ${getColor('muted')};
  margin-bottom: ${emUnit(2, toUnit(32))};
`

const Excerpt = styled.div`
  ${configureFontSize(14, 20)}
  color: ${getColor('muted')};
`

const Button = styled(Link)`
  ${configureFontSize(14, 16)}
  ${padding(
    emUnit(1.5, toUnit(14)),
    emUnit(2, toUnit(14)),
  )}
  
  transition: transform 0.25s ease-out;
  background-color: ${getColorPalette('green', 'lightest')};
  border-radius: ${emUnit(1, toUnit(14))};

  position: absolute;
  bottom: ${emUnit(2)};
  right: ${emUnit(2)};

  &:hover {
    background-color: ${getColor('accent')};
    color: white;
  }
`

const Title: React.FC<
  { children: string } & Pick<HeadingProps, 'size'>
> = function (props) {
  return <StyledTitle breakpoint="small" level="h4" {...props} />
}

const StyledMetadata = styled(Metadata)`
  position: absolute;
  bottom: ${emUnit(2.5)};
  left: ${emUnit(3)};
  > * {
    transition: transform 0.25s ease-out;
  }
`

const featuredStyles = css`
  ${Image} {
    flex: 8;
  }
  ${ImageLink} {
    top: 0;
    bottom: 0;
    width: ${(8 / 13) * 100}%;
  }
  ${Content} {
    ${padding(emUnit(3))}
    flex: 5;
  }
  ${StyledMetadata} {
    bottom: ${emUnit(3)};
    left: ${emUnit(3)};
  }
`

const Wrapper = styled.div<Pick<VerticalPostPropsFeature, 'featured'>>`
  perspective: 2000px;
  background-color: ${getColor('white')};
  border-radius: ${emUnit(3)};
  box-shadow: 0px 20px 60px ${getColor('shadow')};
  overflow: hidden;
  display: flex;

  ${(props) =>
    props.featured
      ? featuredStyles
      : css`
          flex-direction: column;
          ${Image} {
            flex: 0 1 ${imageHeight};
          }
          ${ImageLink} {
            right: 0;
            left: 0;
            height: ${imageHeight};
          }
          ${Content} {
            flex: auto;
          }
        `}
`

export const VerticalPostFeature: React.FC<VerticalPostPropsFeature> = function ({
  className,
  featured,
  post,
}) {
  const excerpt = `${post.excerpt.substring(0, 200)}...`
  const postLink = `/${post.uid}`

  const [titleSize, imageType] = featured
    ? (['md', 'feature'] as const)
    : (['sm', 'poster'] as const)

  return (
    <Wrapper className={className} featured={featured}>
      <PostImage image={post.image} type={imageType}>
        {(imageProps) => (
          <>
            <Image {...imageProps} />
            <ImageLink to={postLink} />
          </>
        )}
      </PostImage>
      <Content>
        <StyledMetadata post={post} />
        <Title size={titleSize}>{post.title}</Title>
        {featured && <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />}
        <Button to={postLink}>Lire d'article</Button>
      </Content>
    </Wrapper>
  )
}
