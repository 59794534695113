import { RawPostCategory, RawPostCategoryEdges } from '../../types'

export const extractPostCategoriesFromEdges = ({
  edges,
}: RawPostCategoryEdges) => edges.map(({ node }) => node)

export const postCategorySelector = (postCategories: RawPostCategory[]) => (
  categoryId: string,
) => {
  return postCategories.find(({ prismicId }) => prismicId === categoryId)
}

export const createGetPostCategory = (
  postCategoryEdges: RawPostCategoryEdges,
) => postCategorySelector(extractPostCategoriesFromEdges(postCategoryEdges))
