import { graphql, useStaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import { em, fluidRange } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { SiteMetadataQuery } from '../../../types'
import { createGetMetadata } from '../../selectors/siteMetadata'
import { emUnit } from '../../theme/helpers/grid'
import { getBreakpoint, getColor } from '../../theme/selectors'
import { Logo } from '../Logo'
import { Tagline } from '../Tagline'

const StyledHeader = styled.div`
  ${(props) =>
    fluidRange(
      {
        prop: 'padding',
        fromSize: emUnit(6),
        toSize: emUnit(9),
      },
      `${em(getBreakpoint('small')(props))}`,
      `${em(getBreakpoint('veryLarge')(props))}`,
    )}
  display: flex;
  justify-content: center;
  z-index: 2;
`

const StyledLogo = styled(Logo)`
  display: block;
  color: ${getColor('primary')};
`
const StyledTagline = styled(Tagline)`
  color: ${getColor('accent')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
`

const StyledSignature = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.div``

export function Header() {
  const data = useStaticQuery<SiteMetadataQuery<'title'>>(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const getMetadata = createGetMetadata(data)
  return (
    <StyledHeader>
      <StyledSignature to="/">
        <Title title={getMetadata('title')}>
          <StyledLogo />
        </Title>
        <StyledTagline />
      </StyledSignature>
    </StyledHeader>
  )
}
