import { widowProofString } from '../../utils/string'

export interface WidowlessTextHookProps {
  disable?: boolean
  text: string
  wordCount?: number
}

export function useWidowlessText({
  disable,
  text,
  wordCount = 1,
}: WidowlessTextHookProps) {
  return !disable ? widowProofString(text, wordCount) : text
}
