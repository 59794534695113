import { margin } from 'polished'
import styled from 'styled-components'

import { emUnit } from '../../../theme/helpers/grid'
import { getColor } from '../../../theme/selectors'
import { Avatar } from '../../Avatar'

export const AuthorAvatar = styled(Avatar)`
  border: ${emUnit(0.5)} solid ${getColor('backgroundPrimary')};
  ${margin(emUnit(-4.5), 'auto', emUnit(2))}
`
