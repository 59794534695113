import * as React from 'react'
import styled from 'styled-components'

import { Post } from '../../../types'
import { Category, PublicationDate } from '../Post/components'

interface PostMetadataProps {
  className?: string
  post: Partial<Post>
}

const Wrapper = styled.div`
  font-family: Woodland;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: initial;
`

export const PostMetadata: React.FC<PostMetadataProps> = function ({
  className,
  children,
  post,
}) {
  return (
    <Wrapper className={className}>
      {children || (
        <>
          <Category>{post.category}</Category>
          <PublicationDate>{post.publicationDate}</PublicationDate>
        </>
      )}
    </Wrapper>
  )
}
