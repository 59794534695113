import * as React from 'react'
import styled, { useTheme } from 'styled-components'

import { getHeading } from '../../theme/selectors'
import { HeadingType } from '../../theme/tokens/typography'
import { Typography } from './Typography'

export interface HeadingProps {
  className?: string
  level: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  size: HeadingType
}

export const Heading: React.FC<HeadingProps> = function({
  children,
  className,
  level,
  size,
}) {
  const theme = useTheme()
  const typographyConfig = getHeading(size)({ theme })
  return (
    <Typography as={level} config={typographyConfig} className={className}>
      {children}
    </Typography>
  )
}
