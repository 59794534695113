import { margin } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { emUnit } from '../../../theme/helpers/grid'
import { getColor, getColorPalette } from '../../../theme/selectors'
import { Logo } from '../../Logo'
import { LogoVidaVerde } from '../../LogoVidaVerde'

interface LogosProps {}

const StyledLogo = styled(Logo)`
  height: ${emUnit(4)};
  color: ${getColor('white')};
`

const Divider = styled.div`
  width: ${emUnit(8)};
  height: 1px;
  background-color: ${getColorPalette('green', 'light')};
  ${margin(emUnit(2), null)}
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const Logos: React.FC<LogosProps> = function ({}) {
  return (
    <Wrapper>
      <StyledLogo />
      <Divider />
      <a href="https://www.vidaverdeguesthouse.com" target="_blank">
        <LogoVidaVerde />
      </a>
    </Wrapper>
  )
}
