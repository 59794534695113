import styled, { css } from 'styled-components'

import {
  configureFluidFontSize,
  configureFluidLineHeight,
  configureFluidTypography,
  configureFontSize,
} from '../../theme/helpers/typography'
import { TypographyConfig } from '../../theme/tokens/typography'

interface TextProps {
  config: TypographyConfig
}

const BaseTypography = styled('p')<TextProps>((props) => {
  const { fontFamily, fontWeight, letterSpacing } = props.config
  return css({
    fontFamily,
    fontWeight,
    letterSpacing,
  })
})

export const Typography = styled(BaseTypography)<TextProps>((props) => {
  const { fontSize, lineHeight } = props.config

  if (Array.isArray(fontSize)) {
    if (Array.isArray(lineHeight)) {
      return configureFluidTypography(fontSize, lineHeight)
    }
    return configureFluidFontSize(fontSize, lineHeight)
  }

  if (Array.isArray(lineHeight)) {
    return configureFluidLineHeight(fontSize, lineHeight)
  }

  return configureFontSize(fontSize, lineHeight)
})
