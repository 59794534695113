import { css } from 'styled-components'

import { PropsWithTheme, Theme } from '../../types/theme'
import { BreakpointType } from './tokens/breakpoints'
import { ThemeColorPalette } from './tokens/colors'
import { HeadingType } from './tokens/typography'

export type ThemeAttribute = keyof Theme
export type ThemeAttributeOptions<
  Attribute extends ThemeAttribute
> = keyof Theme[Attribute]

type ColorPaletteSelector = <
  PaletteName extends keyof Theme['palettes'],
  ShadeName extends keyof ThemeColorPalette[PaletteName]
>(
  ...path: [PaletteName, ShadeName]
) => (props: PropsWithTheme) => Theme['palettes'][PaletteName][ShadeName]

type ThemeAttributeSelector<Attribute extends ThemeAttribute> = (
  attribute: ThemeAttributeOptions<Attribute>,
) => (
  props: PropsWithTheme,
) => Theme[Attribute][ThemeAttributeOptions<Attribute>]

type ThemeHeadingSelector = (
  type: HeadingType,
) => (props: PropsWithTheme) => Theme['typography']['headings'][HeadingType]

export const getBreakpoint: ThemeAttributeSelector<'breakpoints'> = (
  attribute,
) => ({ theme }) => theme.breakpoints[attribute]

export const getColor: ThemeAttributeSelector<'colors'> = (attribute) => ({
  theme,
}) => theme.colors[attribute]

export const getColorPalette: ColorPaletteSelector = (palette, color) => ({
  theme,
}) => theme['palettes'][palette][color]

export const getHeading: ThemeHeadingSelector = (type) => ({ theme }) =>
  theme.typography.headings[type]

export const getMediaQuery: ThemeAttributeSelector<'mediaQueries'> = (
  breakpoint,
) => ({ theme }) => theme.mediaQueries[breakpoint]

export function mediaQuery(
  breakpoint: keyof Theme['breakpoints'],
  isMaxWidth = false,
) {
  return (...args: any) => (props: PropsWithTheme) => {
    let bp = getBreakpoint(breakpoint)(props)
    return isMaxWidth
      ? css`
          @media screen and (max-width: ${bp - 1}px) {
            ${args}
          }
        `
      : css`
          @media screen and (min-width: ${bp}px) {
            ${args}
          }
        `
  }
}

export function getBreakpointByIndex(index: number) {
  return (props: PropsWithTheme) => {
    return Object.keys(props.theme.breakpoints)[index] as BreakpointType
  }
}

export function mediaQueryByIndex(breakpointIndex: number, isMaxWidth = false) {
  return (...args: any) => (props: PropsWithTheme) => {
    const breakpoint = getBreakpointByIndex(breakpointIndex)(props)
    return mediaQuery(breakpoint, isMaxWidth)(args)(props)
  }
}
