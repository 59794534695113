import * as CSS from 'csstype'

import { Theme } from '../../../types/theme'
import { FluidSize } from './breakpoints'

export type HeadingType = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'
export type BodyTextType = 'lg' | 'md' | 'sm'

export type FluidCSSProperty<Prop> = FluidSize | Prop

export interface TypographyConfig {
  fontFamily: CSS.FontFamilyProperty
  fontSize: FluidCSSProperty<number>
  fontWeight?: CSS.FontWeightProperty
  letterSpacing?: CSS.LetterSpacingProperty<string | number>
  lineHeight: FluidCSSProperty<number>
}

export type TypographyConfigElement<
  T extends keyof TypographyConfig
> = TypographyConfig[T]

export const systemFont = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`
export const PTSerif = `PT Serif, ${systemFont}`
export const Woodland = `Woodland, PT Serif, ${systemFont}`

const baseHeading: TypographyConfig = {
  fontFamily: Woodland,
  fontSize: 16,
  fontWeight: 100,
  lineHeight: 1,
}

const baseBody: TypographyConfig = {
  fontFamily: PTSerif,
  fontSize: 16,
  lineHeight: 1,
}

export const typography: Theme['typography'] = {
  headings: {
    xxl: {
      ...baseHeading,
      fontSize: [42, 56, 72, 96, 120],
      lineHeight: [42, 56, 72, 96, 120],
    },
    xl: {
      ...baseHeading,
      fontSize: [42, 56, null, 72, 96],
      lineHeight: [42, 56, null, 72, 96],
    },
    lg: {
      ...baseHeading,
    },
    md: {
      ...baseHeading,
      fontSize: [20, null, 24, 32],
      lineHeight: [20, null, 24, 32],
      fontWeight: 'normal',
      letterSpacing: '-0.02em',
    },
    sm: {
      ...baseHeading,
      fontSize: [20, null, null, 26],
      lineHeight: [24, null, null, 32],
      fontWeight: 'normal',
    },
    xs: {
      ...baseHeading,
    },
    xxs: {
      ...baseHeading,
    },
  },
  body: {
    lg: {
      ...baseBody,
      fontSize: [18, null, null, 21],
      lineHeight: [28, null, null, 32],
    },
    md: {
      ...baseBody,
    },
    sm: {
      ...baseBody,
    },
  },
}
