import { margin, padding } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components'

import { percentCol, unit } from '../../../theme/helpers/grid'
import { mediaQuery } from '../../../theme/selectors'
import { WidowlessTitle } from '../../text/WidowlessTitle'

interface TitleProps {
  children: string
}

const StyledTitle = styled(WidowlessTitle)(
  () => css`
    text-align: center;

    ${margin(unit(4), percentCol(-2), unit(6))}

    ${padding(null, percentCol(1))}

    ${mediaQuery('medium')`
      padding: 0;
      ${margin(unit(6), null, unit(7))}
    `}

    ${mediaQuery('large')`
      ${margin(unit(7), percentCol(-2), unit(9))}
    `}
  `,
)

export const Title: React.FC<TitleProps> = function(props) {
  return <StyledTitle breakpoint="verySmall" level="h2" size="xl" {...props} />
}
