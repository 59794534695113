import * as React from 'react'

export function Tagline(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      width="192"
      height="31"
      viewBox="0 0 192 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2852 17.1176L13.6078 16.4373C12.4223 17.5712 11.2086 18.1665 10.1643 18.3083C10.5877 17.3161 10.8699 16.2672 10.8699 15.2466C10.8699 13.6024 9.99496 12.5252 8.64015 12.5252C7.08777 12.5252 6.26924 13.9143 6.26924 15.4451C6.26924 16.9475 7.03132 18.535 8.61193 19.0737C7.82162 20.321 6.80552 21.1998 5.93054 21.1998C5.14023 21.1998 4.68863 20.6328 4.68863 19.6123C4.68863 15.7569 9.88206 5.06961 10.4748 3.08523L9.51513 2.80175C6.86197 9.43525 4.23702 12.752 0.313723 16.4373L0.991128 17.1176C2.79754 15.4167 5.14023 13.0638 6.55149 10.3424C4.9991 13.4324 3.67252 17.8263 3.67252 19.6123C3.67252 21.1431 4.49105 22.1069 5.93054 22.1069C7.25712 22.1069 8.69661 20.9163 9.68449 19.2438C9.74094 19.2438 9.76916 19.2438 9.82561 19.2438C11.2086 19.2438 12.8175 18.5917 14.2852 17.1176ZM7.28534 15.3884C7.28534 14.3395 7.73695 13.4324 8.64015 13.4324C9.37401 13.4324 9.85384 14.1127 9.85384 15.2466C9.85384 16.2105 9.54336 17.2877 9.09176 18.2232C7.84985 17.883 7.28535 16.579 7.28534 15.3884Z"
        fill="currentColor"
      />
      <path
        d="M26.3854 16.4373C24.325 18.4216 22.0105 19.4705 20.1759 19.4705C17.9461 19.4705 16.309 18.0248 15.9421 15.4167C20.9662 9.97386 23.2242 3.85063 23.2242 2.14974C23.2242 1.15755 22.6032 0.647278 21.8129 0.647278C18.9622 0.647278 14.8695 8.72654 14.8695 14.2828C14.8695 14.5946 14.8695 14.8781 14.8978 15.1616C14.4744 15.6152 14.051 16.0404 13.5994 16.4373L14.2768 17.1176C14.5591 16.8625 14.8131 16.6074 15.0953 16.3239C15.7445 18.9036 17.6921 20.3493 20.1477 20.3493C22.2363 20.3493 24.833 19.2437 27.0628 17.1176L26.3854 16.4373ZM21.8129 1.55442C22.0105 1.55442 22.2081 1.72451 22.2081 2.14974C22.2081 3.62385 20.3452 9.03837 15.8856 14.1127C15.9703 8.27297 20.0065 1.55442 21.8129 1.55442Z"
        fill="currentColor"
      />
      <path
        d="M39.3443 17.1176L38.6669 16.4373C37.1427 17.8263 36.1831 18.1382 35.1388 18.1382C34.9976 18.1382 34.8847 18.1382 34.7718 18.1382C36.0137 16.7774 36.8605 15.1899 36.8605 13.9143C36.8605 12.7236 36.1266 11.5614 34.6871 11.5614C33.2759 11.5614 31.6106 12.6386 31.6106 15.1049C31.6106 16.6357 32.2316 17.7696 33.1348 18.4216C31.8646 19.4989 30.3405 20.2076 29.155 20.2076C28.1389 20.2076 27.7438 19.6973 27.7438 18.6768C27.7438 16.3239 29.7477 13.8292 32.034 12.7236L31.5824 11.9299C30.3405 12.5252 29.2961 13.3757 28.4776 14.3678C27.8002 15.0482 27.1228 15.7853 26.3889 16.4373L26.9817 17.0609C26.8123 17.5995 26.7276 18.1382 26.7276 18.6484C26.7276 20.1509 27.4615 21.1147 29.1268 21.1147C30.7921 21.1147 32.5703 20.1792 34.0097 18.8752C34.3767 18.9886 34.7436 19.0453 35.1388 19.0453C36.4653 19.0453 37.7637 18.6484 39.3443 17.1176ZM32.6267 15.1049C32.6267 13.1489 33.8968 12.4685 34.6871 12.4685C35.4775 12.4685 35.8444 13.1205 35.8444 13.971C35.8444 15.2466 34.9976 16.6357 33.8404 17.798C32.9936 17.2594 32.6267 16.2105 32.6267 15.1049Z"
        fill="currentColor"
      />
      <path
        d="M48.7876 17.1176L48.1102 16.4373C46.953 17.5712 45.6828 18.4216 44.328 19.1304C45.9086 15.4451 47.1223 11.7031 47.9126 9.23681C42.5498 10.144 38.2314 15.5018 38.2314 17.8547C38.2314 18.6201 38.7959 19.1871 39.6427 19.1871C41.3644 19.1871 43.3684 17.0042 45.3159 13.7442C44.6667 15.5868 43.7635 17.883 42.8886 19.8107C37.1024 22.3621 30.7799 22.7022 30.7799 27.6632C30.7799 29.3357 32.2759 30.5547 33.9694 30.5547C37.9774 30.5547 41.2515 25.7922 43.7071 20.4911C45.4853 19.669 47.2352 18.6201 48.7876 17.1176ZM39.6427 18.2799C39.3604 18.2799 39.2475 18.0815 39.2475 17.8547C39.2475 16.1821 42.1547 12.2701 46.1062 10.7109C43.7071 15.0482 41.0257 18.2799 39.6427 18.2799ZM33.9129 29.6476C32.7839 29.6476 31.7961 28.8255 31.7961 27.6632C31.7961 23.6944 36.9048 23.2409 42.2676 21.1147C39.9531 25.7355 36.9895 29.6476 33.9129 29.6476Z"
        fill="currentColor"
      />
      <path
        d="M71.6771 16.4373C68.8546 18.9886 66.3426 20.0092 64.7337 20.0092C63.5483 20.0092 62.7015 19.4705 62.7015 18.4216C62.7015 18.0248 62.758 17.6279 62.8991 17.2027C68.1772 16.4656 72.0441 14.3111 72.0441 11.9582C72.0441 10.9944 71.3102 10.3707 70.2377 10.3707C67.2176 10.3707 63.3507 13.3757 62.0806 16.4656C60.3024 18.0815 58.6089 19.4138 57.2823 19.4138C56.6896 19.4138 56.492 19.102 56.492 18.6768C56.492 17.5145 57.5645 15.275 59.7943 11.8448L58.9476 11.3346C56.5202 15.0766 53.2743 19.5839 50.7905 19.5839C50.226 19.5839 49.8591 19.3004 49.8591 18.6768C49.8591 16.9475 51.8348 14.0844 53.5001 12.1L52.7098 11.533C50.9881 13.5457 50.1131 14.4812 48.1091 16.4373L48.7865 17.1176C49.0405 16.8908 49.2946 16.6357 49.5486 16.4089C49.097 17.2594 48.843 18.0531 48.843 18.6768C48.843 19.8107 49.6333 20.4911 50.7905 20.4911C52.2864 20.4911 53.9799 19.2437 55.7581 17.2027C55.5606 17.7696 55.4759 18.2799 55.4759 18.6768C55.4759 19.7257 56.1251 20.321 57.2823 20.321C58.6089 20.321 60.1048 19.4138 61.6854 18.1098C61.6854 18.2232 61.6854 18.3366 61.6854 18.4216C61.6854 19.9808 62.9838 20.9163 64.7337 20.9163C66.766 20.9163 69.3909 19.8107 72.3545 17.1176L71.6771 16.4373ZM70.2377 11.2779C70.7739 11.2779 71.028 11.5047 71.028 11.9582C71.028 13.6875 67.7538 15.5018 63.3225 16.2105C64.6491 13.6875 67.8385 11.2779 70.2377 11.2779Z"
        fill="currentColor"
      />
      <path
        d="M85.4782 19.0737C85.1677 19.0737 84.9419 18.9036 84.9419 18.5067C84.9419 16.7208 87.341 12.3551 91.4619 3.68054L90.5587 3.28367C89.2321 6.09015 88.0749 8.4714 87.1152 10.4558C82.3452 11.7315 78.5065 14.6797 78.5065 17.3444C78.5065 18.45 79.2404 19.1587 80.3412 19.1587C81.6678 19.1587 82.9943 18.2516 84.3774 16.494C84.0669 17.3444 83.9258 18.0248 83.9258 18.5067C83.9258 19.5556 84.5749 19.9808 85.4782 19.9808C86.6354 19.9808 88.1031 19.2721 90.5305 17.1176L89.8531 16.4373C87.6515 18.3933 86.3531 19.0737 85.4782 19.0737ZM80.3412 18.2516C79.8331 18.2516 79.5226 17.9681 79.5226 17.3444C79.5226 15.275 82.6274 12.837 86.5507 11.5897C84.2362 15.8703 82.0629 18.2516 80.3412 18.2516Z"
        fill="currentColor"
      />
      <path
        d="M95.9654 2.40487C95.9654 1.35599 95.4009 0.845717 94.6952 0.845717C93.7638 0.845717 93.2557 1.58277 93.2557 2.20643C93.2557 2.88679 93.8202 3.34037 94.4412 3.34037C94.5259 3.34037 94.6106 3.31202 94.6952 3.31202C94.3283 4.70108 93.538 5.77832 92.6912 6.57207L93.3122 7.11069C94.8928 5.77832 95.9654 3.76559 95.9654 2.40487Z"
        fill="currentColor"
      />
      <path
        d="M112.557 9.2935C113.375 9.2935 114.109 8.61314 114.109 7.73435C114.109 7.28078 113.799 6.99729 113.291 6.99729C112.444 6.99729 111.738 7.73435 111.738 8.44306C111.738 8.95332 112.077 9.2935 112.557 9.2935ZM114.109 16.4373C112.02 18.3933 110.355 19.3004 109.395 19.3004C108.718 19.3004 108.436 18.9603 108.436 18.4216C108.436 16.5223 110.891 12.7803 110.891 12.7803L109.988 12.3835C108.859 13.8859 105.613 18.7051 102.113 18.7051C101.662 18.7051 101.351 18.45 101.351 17.9397C101.351 16.494 103.073 13.971 104.964 10.314C101.408 10.5692 95.678 14.2828 95.678 17.2877C95.678 18.2516 96.2708 18.7618 97.2586 18.7618C98.3594 18.7618 99.4884 17.9397 100.561 16.7774C100.42 17.2594 100.335 17.7129 100.335 18.1098C100.335 18.9319 100.9 19.6406 101.944 19.6406C104.174 19.6406 106.319 17.9397 107.956 16.2388C107.646 17.0326 107.42 17.798 107.42 18.4216C107.42 19.4989 108.097 20.2076 109.395 20.2076C110.666 20.2076 112.5 19.2154 114.786 17.1176L114.109 16.4373ZM97.2586 17.8547C96.8917 17.8547 96.6941 17.6279 96.6941 17.2877C96.6941 15.7853 99.2626 12.6953 103.384 11.448C101.125 15.1332 98.7263 17.8547 97.2586 17.8547Z"
        fill="currentColor"
      />
      <path
        d="M139.669 16.4373C137.608 18.4216 135.294 19.4705 133.459 19.4705C131.145 19.4705 129.564 17.9114 128.915 15.275C131.794 11.1928 133.487 6.03345 133.487 3.73724C133.487 2.46157 132.669 2.06469 131.878 2.06469C129.62 2.06469 127.588 6.68546 127.588 12.0716C127.588 13.1489 127.673 14.1694 127.842 15.0482C125.81 17.883 123.241 20.0375 120.391 20.0375C118.161 20.0375 116.75 18.1382 116.439 15.4451C121.463 9.97386 123.721 3.85063 123.721 2.14974C123.721 1.15755 123.1 0.647278 122.31 0.647278C119.459 0.647278 115.367 8.10288 115.367 14.2828C115.367 14.5946 115.367 14.8781 115.395 15.1899C114.971 15.6152 114.548 16.0404 114.097 16.4373L114.774 17.1176C115.028 16.8625 115.31 16.6074 115.564 16.3522C116.129 19.102 117.907 20.9163 120.363 20.9163C123.383 20.9163 126.064 18.9319 128.181 16.2672C129.028 18.8185 130.778 20.3493 133.431 20.3493C135.519 20.3493 138.116 19.2437 140.346 17.1176L139.669 16.4373ZM122.31 1.55442C122.508 1.55442 122.705 1.72451 122.705 2.14974C122.705 3.62385 120.842 9.03837 116.383 14.1127C116.467 8.27297 120.504 1.55442 122.31 1.55442ZM128.576 12.1C128.576 7.59261 130.326 2.97184 131.878 2.97184C132.189 2.97184 132.471 3.14193 132.471 3.73724C132.471 5.77832 131.003 10.1723 128.661 13.8576C128.604 13.319 128.576 12.7236 128.576 12.1Z"
        fill="currentColor"
      />
      <path
        d="M165.699 16.4373C163.893 18.0531 161.353 19.8107 159.715 19.8107C159.123 19.8107 158.671 19.5839 158.671 18.9036C158.671 17.7413 159.715 15.275 161.945 11.8448L161.098 11.3346C158.643 15.0766 155.03 19.9525 152.518 19.9525C152.01 19.9525 151.756 19.669 151.756 19.0453C151.756 17.6279 153.534 14.6797 155.651 12.1L154.861 11.533C150.683 16.6641 146.224 20.6895 141.68 20.6895C140.494 20.6895 139.901 20.1509 139.901 19.102C139.901 18.535 140.071 17.883 140.353 17.2027C145.688 16.494 149.583 14.3395 149.583 11.9582C149.583 10.9944 148.849 10.3707 147.776 10.3707C144.135 10.3707 138.885 15.4734 138.885 19.102C138.885 20.6612 139.93 21.5967 141.68 21.5967C145.349 21.5967 148.792 19.1871 151.587 16.3806C151.05 17.4578 150.74 18.365 150.74 19.0453C150.74 20.1792 151.417 20.8596 152.518 20.8596C154.099 20.8596 156.074 19.3288 158.078 16.9475C157.796 17.7129 157.655 18.3933 157.655 18.9036C157.655 20.2643 158.558 20.7179 159.715 20.7179C161.635 20.7179 164.373 18.9319 166.377 17.1176L165.699 16.4373ZM147.776 11.2779C148.312 11.2779 148.567 11.5047 148.567 11.9582C148.567 13.6875 145.321 15.4734 140.917 16.2105C142.47 13.6875 145.546 11.2779 147.776 11.2779Z"
        fill="currentColor"
      />
      <path
        d="M177.776 16.4373C175.179 18.8752 172.075 20.7462 170.099 20.7462C169.28 20.7462 168.913 20.3777 168.913 19.6973C168.913 18.3366 171.03 16.1821 173.542 13.9993C171.933 14.0844 170.438 14.056 169.337 13.8292C169.591 13.3473 169.76 12.8937 169.76 12.4402C169.76 11.5047 169.083 10.8527 168.095 10.8527C167.163 10.8527 166.401 11.5897 166.401 12.4969C166.401 13.3473 166.994 13.9143 167.813 14.2828C167.305 14.8781 166.599 15.5868 165.696 16.4373L166.373 17.1176C167.361 16.1821 168.208 15.36 168.8 14.5946C169.619 14.7931 170.466 14.8781 171.143 14.9065C169.252 16.7208 167.897 18.3083 167.897 19.8958C167.897 20.973 168.744 21.6534 170.099 21.6534C172.357 21.6534 175.687 19.7257 178.453 17.1176L177.776 16.4373ZM167.417 12.4969C167.417 12.1 167.728 11.7598 168.095 11.7598C168.518 11.7598 168.744 12.0149 168.744 12.4402C168.744 12.7803 168.603 13.1489 168.349 13.5457C167.756 13.319 167.417 12.9788 167.417 12.4969Z"
        fill="currentColor"
      />
      <path
        d="M191.43 17.1176L190.752 16.4373C188.607 18.5634 186.377 19.0737 183.668 19.3004C183.639 17.7413 182.426 15.842 182.426 13.8859C182.426 12.8937 182.793 11.9299 183.498 11.0511L182.708 10.4841C182.482 10.796 182.285 11.0794 182.115 11.3629C180.45 13.971 179.631 14.623 177.769 16.4373L178.446 17.1176C179.631 15.9553 180.506 15.0766 181.41 13.971C181.41 16.1254 182.652 18.0248 182.68 19.3571C180.309 19.4705 177.881 19.3004 177.881 21.2848C177.881 22.3621 178.841 23.1275 180.168 23.1275C181.889 23.1275 183.272 21.9368 183.611 20.2359C183.611 20.2359 183.639 20.2359 183.668 20.2076C185.841 20.0092 188.635 19.7824 191.43 17.1176ZM180.168 22.2203C179.406 22.2203 178.898 21.8234 178.898 21.2848C178.898 20.2076 180.365 20.3777 182.567 20.2926C182.256 21.4833 181.325 22.2203 180.168 22.2203Z"
        fill="currentColor"
      />
    </svg>
  )
}
