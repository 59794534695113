import { em, stripUnit } from 'polished'
import { css } from 'styled-components'

import { PropsWithTheme } from '../../../types/theme'
import { mediaQueryByIndex } from '../selectors'
import { FluidSize } from '../tokens/breakpoints'

export function configureFontSize(fontSize: number, lineHeight = fontSize) {
  return css`
    font-size: ${em(fontSize)};
    line-height: ${stripUnit(em(lineHeight, fontSize))};
  `
}

export function configureFluidTypography(
  [firstFontSize, ...restFontSize]: FluidSize,
  [firstLineHeight, ...restLineHeight]: FluidSize,
) {
  return (props: PropsWithTheme) => css`
    ${configureFontSize(firstFontSize, firstLineHeight)}
    ${restFontSize.map(
      (fs, index) =>
        fs &&
        mediaQueryByIndex(index)(configureFontSize(fs, restLineHeight[index]))(
          props,
        ),
    )}
  `
}

export function configureFluidFontSize(
  [firstFontSize, ...restFontSize]: FluidSize,
  lineHeight: number,
) {
  return (props: PropsWithTheme) => css`
    ${configureFontSize(firstFontSize, lineHeight)}
    ${restFontSize.map(
      (fs, index) =>
        fs &&
        mediaQueryByIndex(index)(configureFontSize(fs, lineHeight))(props),
    )}
  `
}

export function configureFluidLineHeight(
  fontSize: number,
  [firstLineHeight, ...restLineHeight]: FluidSize,
) {
  return (props: PropsWithTheme) => css`
    ${configureFontSize(fontSize, firstLineHeight)}
    ${restLineHeight.map(
      (lh, index) =>
        lh && mediaQueryByIndex(index)(configureFontSize(fontSize, lh))(props),
    )}
  `
}
