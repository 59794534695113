import { margin, timingFunctions, transparentize } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { emUnit, percentCol, remUnit } from '../../../theme/helpers/grid'
import { configureFontSize } from '../../../theme/helpers/typography'
import { getColor, mediaQuery } from '../../../theme/selectors'
import { Avatar as BaseAvatar } from '../../Avatar'
import { SocialHandle } from '../../SocialHandle'
import { WidowlessText } from '../../text/WidowlessText'

interface AboutProps {
  bio: string
  className?: string
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${emUnit(2)};

  > * {
    &:not(:last-child) {
      margin-bottom: ${emUnit(2)};
    }
  }

  ${mediaQuery('small')`
    align-items: flex-start;
    margin-top: 0;
  `}
`

const StyledBio = styled.p`
  ${configureFontSize(14, 20)}
`

const Links = styled.div`
  display: flex;
  align-items: center;
`

const IllustrationAuthor = styled.div`
  ${configureFontSize(12, 16)}
  margin-left: ${percentCol(1)};

  a {
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      width: ${`calc(100% + ${remUnit(0.5)})`};
      height: 100%;
      left: ${remUnit(-0.25)};
      background: ${(props) =>
        transparentize(0.75, getColor('primary')(props))};
      transition: 0.15s ${timingFunctions('easeInOutCirc')};
      transform: scaleY(0.25);
      transform-origin: bottom;
    }

    &:hover::after {
      background: ${(props) =>
        transparentize(0.85, getColor('primary')(props))};
      transform: translateY(1px) scaleY(1);
    }
  }
`

const Avatar = styled(BaseAvatar)`
  flex: none;
  ${margin(null, percentCol(0.5))}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${getColor('white')};

  ${mediaQuery('small')`
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  `}
`

const Bio: React.FC<{ children: string }> = ({ children, ...props }) => (
  <WidowlessText text={children} wordCount={2}>
    {(output) => <StyledBio {...props}>{output}</StyledBio>}
  </WidowlessText>
)

const AboutComponent: React.FC<AboutProps> = function ({
  bio,
  children,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <Avatar />
      <Content>
        <Bio>{bio}</Bio>
        <Links>
          <SocialHandle name="instagram" />
          <IllustrationAuthor>
            Illustration par{' '}
            <a href="https://www.catherinelavoie.com">catherinelavoie.com</a>.
          </IllustrationAuthor>
        </Links>
      </Content>
    </Wrapper>
  )
}

export const About = styled(AboutComponent)``
