import * as React from 'react'
import styled from 'styled-components'

import { getColor } from '../../../theme/selectors'

const Curve: React.FC<React.SVGAttributes<SVGElement>> = function ({
  style,
  ...props
}) {
  return (
    <svg
      width="1440"
      height="76"
      viewBox="0 0 1440 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1440 -1H0L60 11.4238C120 24.2914 240 50.0265 360 56.2384C432.329 60.25 504.658 57.1689 576.986 54.0879C624.658 52.0573 672.329 50.0265 720 50.0265C799.091 50.0265 878.182 55.6161 957.273 61.2058C998.182 64.097 1039.09 66.9883 1080 69.106C1200 75.3179 1320 75.3179 1380 75.3179H1440V-1Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const FooterCurve = styled(Curve)`
  width: 100%;
  height: ${(76 / 1440) * 100}vw;
  display: block;
  z-index: 1;
  ${(props) => ({
    ...props.style,
    color: props.style?.color || getColor('backgroundPrimary')(props),
  })}
`
