import { graphql, useStaticQuery } from 'gatsby'
import { padding } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

import { SiteMetadataQuery } from '../../../types'
import { createGetMetadata } from '../../selectors/siteMetadata'
import { emUnit, percentCol } from '../../theme/helpers/grid'
import { getMediaQuery } from '../../theme/selectors'
import { About } from './components/About'
import { Logos } from './components/Logos'

const StyledFooter = styled.footer`
  position: sticky;
  bottom: 0;

  ${padding(null, percentCol(1), emUnit(3))}
  padding-top: calc(76 / 1440 * 100vw + ${emUnit(3)});
  margin-top: ${(-76 / 1440) * 100}vw;
  background-color: #9B9F92;

  > * {
    margin: ${emUnit(4)} 0;
  }

  @media ${getMediaQuery('small')} {
    display: flex;
    justify-content: space-between;

    ${About} {
      max-width: ${percentCol(14)};
    }
  }

  @media ${getMediaQuery('large')} {
    ${About} {
      max-width: ${percentCol(10)};
    }
  }
`

export function Footer() {
  const data = useStaticQuery<SiteMetadataQuery<'bio'>>(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          bio
        }
      }
    }
  `)
  const getMetadata = createGetMetadata(data)
  return (
    <>
      <StyledFooter>
        <About bio={getMetadata('bio')} />
        <Logos />
      </StyledFooter>
    </>
  )
}
