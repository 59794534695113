import { em, rem } from 'polished'

enum LayoutPreference {
  BaseUnit = 8,
  BaseFontSize = 16,
  TotalColumns = 24,
}

const baseFontSizeInUnits =
  LayoutPreference.BaseFontSize / LayoutPreference.BaseUnit

export function col(span: number, totalCols = LayoutPreference.TotalColumns) {
  return span / totalCols
}

export function toPercent(num: number) {
  return num * 100
}

export function toViewportWidth(num: number) {
  return `${num}vw`
}

export function percentCol(span: number, totalCols?: number) {
  return toViewportWidth(toPercent(col(span, totalCols)))
}

export function unit(multiplier: number, base = LayoutPreference.BaseUnit) {
  return multiplier * base
}

export function toUnit(value: number, base = LayoutPreference.BaseUnit) {
  return value / base
}

export function emUnit(
  value: number,
  base = baseFontSizeInUnits,
  unitBase?: number,
) {
  return em(unit(value, unitBase), unit(base, unitBase))
}

export function remUnit(
  value: number,
  base = baseFontSizeInUnits,
  unitBase?: number,
) {
  return rem(unit(value, unitBase), unit(base, unitBase))
}
