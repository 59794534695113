/**
 * Add a non-breaking space (&nbsp;) between the last
 * and second to last words of a string to prevent
 * orphans in a responsive setting.
 *
 * Caution: The resulting string includes an HTML character
 * that needs to be rendered through `dangerouslySetInnerHTML` prop:
 *
 *  <Component
 *    dangerouslySetInnerHTML={{ __html: widowProofString(string) }}
 *  />
 *
 * @param str string
 */
export function widowProofString(str: string, wordCount = 1): string {
  const words = str.split(' ')

  if (words.length <= 1) {
    return str
  }

  const [lastWord, secondToLastWord, ...rest] = words.reverse()
  rest.unshift(`${secondToLastWord}\u00a0${lastWord}`)
  const output = rest.reverse().join(' ')

  if (wordCount > 1) {
    return widowProofString(output, wordCount - 1)
  }

  return output
}
