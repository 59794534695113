import 'destyle.css/destyle.css'

import { createGlobalStyle } from 'styled-components'

import WoodlandWoff from '../assets/fonts/ppwoodland-regular.woff'
import WoodlandWoff2 from '../assets/fonts/ppwoodland-regular.woff2'
import WoodlandLightWoff from '../assets/fonts/ppwoodland-ultralight.woff'
import WoodlandLightWoff2 from '../assets/fonts/ppwoodland-ultralight.woff2'
import { getColor } from '../theme/selectors'

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Woodland';
    font-display: fallback;
    src: 
      url('${WoodlandWoff2}') format('woff2'),
      url('${WoodlandWoff}') format('woff');
  }

  @font-face {
    font-family: 'Woodland';
    font-display: fallback;
    font-weight: 100;
    src: 
      url('${WoodlandLightWoff2}') format('woff2'),
      url('${WoodlandLightWoff}') format('woff');
  }

  *,
  *::before,
  *::after {
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
  }

  body {
    font-family: 'PT Serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    line-height: 1.5;
    background-color: ${getColor('backgroundPrimary')};
    color: ${getColor('primary')};
  }
`
