import * as React from 'react'
import { ThemeProvider } from 'styled-components'

import { themeDark, themeLight } from '../theme'
import { Footer } from './Footer'
import { FooterCurve } from './Footer/components/Curve'
import { GlobalStyles } from './Global'
import { Header } from './Header'
import { Main } from './Main'

interface LayoutProps {
  type?: 'index' | 'post'
}

export const Layout: React.FC<LayoutProps> = function ({
  children,
  type = 'index',
}) {
  const [isDarkMode, setDarkMode] = React.useState(false)
  const theme = isDarkMode ? themeDark : themeLight
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Main animateBackground={type === 'index'}>
        <Header />
        {/* <button onClick={() => setDarkMode(!isDarkMode)}>Dark Mode</button> */}
        {children}
      </Main>
      <FooterCurve
        style={type === 'post' ? { color: theme.colors.white } : undefined}
      />
      <Footer />
    </ThemeProvider>
  )
}
