import { useWidowlessText, WidowlessTextHookProps } from './userWidowlessText'

export interface WidowlessTextProps extends WidowlessTextHookProps {
  children(outputText: string): JSX.Element
}

export function WidowlessText({ children, ...props }: WidowlessTextProps) {
  const WidowlessText = useWidowlessText(props)
  return children(WidowlessText)
}
