import { parseISO } from 'date-fns'

import {
  Post,
  PostExcerpt,
  RawPost,
  RawPostCategory,
  RawPostExcerpt,
} from '../../types'

type CreatePost<RawPostType, PostType> = (
  rawPostData: Partial<RawPostType>,
  rawPostCategoryData: RawPostCategory,
) => PostType

export const createPost: CreatePost<RawPost, Post> = (post, postCategory) => ({
  category: postCategory.data.name,
  content: post.data.content.html,
  excerpt: post.data.excerpt.text,
  image: post.data.image,
  publicationDate: parseISO(post.first_publication_date),
  title: post.data.title.text,
  uid: post.uid,
})

export const createPostExcerpt: CreatePost<RawPostExcerpt, PostExcerpt> = (
  post,
  postCategory,
) => ({
  category: postCategory.data.name,
  excerpt: post.data.excerpt.text,
  image: post.data.image,
  publicationDate: parseISO(post.first_publication_date),
  title: post.data.title.text,
  uid: post.uid,
})
