import { darken, transparentize } from 'polished'

import { Theme } from '../../types/theme'
import { breakpoints, generateMediaQueries } from './tokens/breakpoints'
import { colors, palettes, palettesDark } from './tokens/colors'
import {
  postFeatureImageRatio as postFeatureImage,
  postImageRatio as postImage,
  postPosterImageRatio as postPosterImage,
} from './tokens/ratios'
import { typography } from './tokens/typography'

export const themeLight: Theme = {
  type: 'light',
  breakpoints,
  imageRatios: {
    postFeatureImage,
    postImage,
    postPosterImage,
  },
  mediaQueries: generateMediaQueries(),
  palettes,
  typography,
  colors: {
    primary: palettes.green.darker,
    secondary: palettes.green.base,
    accent: colors.blazeOrange,
    backgroundPrimary: '#FAF6F2',
    backgroundSecondary: '#F2F1EE',
    muted: palettes.green.dark,
    white: 'white',
    shadow: transparentize(0.85, palettes.green.darker),
  },
}

export const themeDark: Theme = {
  ...themeLight,
  type: 'dark',
  palettes: palettesDark,
  colors: {
    primary: palettes.green.lighter,
    secondary: palettes.green.light,
    accent: colors.blazeOrangeDark,
    backgroundPrimary: palettes.green.darker,
    backgroundSecondary: palettes.green.darkest,
    muted: palettes.green.base,
    white: palettes.green.darkest,
    shadow: transparentize(0.8, darken(0.2, palettesDark.green.lightest)),
  },
}

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
