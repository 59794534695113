import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

import { SiteMetadataQuery } from '../../types'
import { SiteSiteMetadataSocial } from '../../types/graphql-types'
import { createGetMetadata } from '../selectors/siteMetadata'
import { remUnit } from '../theme/helpers/grid'
import { configureFontSize } from '../theme/helpers/typography'
import { LogoInstagram } from './LogoInstagram'

type SocialPlatforms = keyof SiteSiteMetadataSocial
interface SocialHandleProps {
  name: SocialPlatforms
}

const Wrapper = styled.a`
  display: flex;
  align-items: center;
`

const Handle = styled.span`
  ${configureFontSize(12, 16)}
  margin-left: ${remUnit(1)};
`

function useSocialLogo(name: SocialPlatforms) {
  switch (name) {
    case 'instagram':
      return LogoInstagram
  }
}

export const SocialHandle: React.FC<SocialHandleProps> = function ({ name }) {
  const Logo = useSocialLogo(name)
  const data = useStaticQuery<SiteMetadataQuery<'social'>>(graphql`
    query SocialHandleQuery {
      site {
        siteMetadata {
          social {
            instagram {
              handle
              link
            }
          }
        }
      }
    }
  `)
  const getMetadata = createGetMetadata(data)
  const { handle, link } = getMetadata('social')[name]
  return (
    <Wrapper href={link} target="_blank" rel="noreferrer">
      <Logo />
      <Handle>{handle}</Handle>
    </Wrapper>
  )
}
