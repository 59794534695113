import { useMedia } from 'react-use'
import { useTheme } from 'styled-components'

import { getBreakpoint, ThemeAttributeOptions } from '../selectors'

export interface ResponsiveHookProps {
  breakpoint: ThemeAttributeOptions<'breakpoints'>
}

export function useResponsive({ breakpoint }: ResponsiveHookProps) {
  const theme = useTheme()
  const minWidth = getBreakpoint(breakpoint)({ theme })
  return useMedia(`(min-width: ${minWidth}px)`)
}
