import * as React from 'react'
import { useTheme } from 'styled-components'

export const LogoVidaVerde: React.FC<React.SVGAttributes<
  SVGElement
>> = function (props) {
  const theme = useTheme()
  return (
    <svg
      width="108"
      height="39"
      viewBox="0 0 108 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={theme.colors.white}>
        <path d="M27.2515 10.2781C27.2398 10.0867 27.2306 9.93633 27.2222 9.7996C27.0387 6.35238 27.0925 7.18584 26.9089 3.73862C26.8787 3.2464 26.8754 3.19171 27.3813 3.16074C28.3649 3.0868 29.3382 3.06839 30.3251 3.04915C30.4072 3.04413 30.5564 3.24085 30.5776 3.36307C30.6323 4.0322 30.6733 4.70216 30.7007 5.37297C30.8145 8.57742 30.6234 7.51905 30.7919 10.7202C30.9847 14.3178 31.2724 17.8959 31.5198 21.4901C31.55 21.9823 31.5525 22.0234 31.0773 22.1073C30.344 22.2346 29.621 22.3063 28.886 22.4062C28.5459 22.4544 28.4103 22.2569 28.3918 21.9561C28.3751 21.6826 28.3857 21.4075 28.3826 21.1332C28.3759 21.0238 28.3821 20.8999 28.3617 20.7914C28.2853 20.4392 28.1717 20.3776 27.8382 20.5352C27.0115 20.9289 26.188 21.3773 25.3289 21.6907C24.0534 22.1531 22.7321 22.3163 21.4246 21.8062C19.7096 21.1426 18.8466 19.8231 18.6412 18.0378C18.3134 15.1484 19.5705 13.0402 21.9342 11.5231C23.4393 10.5527 25.1155 10.1344 26.9089 10.2854C26.9917 10.294 27.1011 10.2873 27.2515 10.2781ZM27.117 13.237C25.937 13.2406 24.8311 13.3358 23.8196 13.8506C22.9945 14.2717 22.4314 14.9374 22.2961 15.8652C22.2082 16.447 22.2031 17.0375 22.2118 17.6271C22.2252 18.5183 22.5742 18.84 23.4808 18.8532C23.6457 18.8568 23.7961 18.8476 23.9585 18.8102C25.1405 18.6143 26.2528 18.1757 27.3181 17.6439C27.3977 17.5978 27.4987 17.4544 27.4937 17.3724C27.3681 15.9939 27.2434 14.6291 27.117 13.237Z" />
        <path d="M89.6215 10.9924C89.6177 10.8966 89.6281 10.8139 89.6243 10.7181C89.5252 7.17134 89.5391 6.48473 89.4399 2.938C89.4275 2.62318 89.5037 2.46937 89.8322 2.45637C90.6813 2.43649 91.5163 2.40345 92.3655 2.38357C93.1188 2.36747 93.1003 2.24483 93.1207 3.10769C93.1777 6.62867 93.0811 7.30485 93.165 10.8111C93.2436 13.8377 93.4317 16.8599 93.5651 19.8844C93.5986 20.733 93.6327 21.5953 93.6937 22.4429C93.7024 22.6619 93.6399 22.8151 93.4214 22.8375C92.6165 22.9379 91.798 23.0388 90.9789 23.1261C90.6789 23.1653 90.5475 22.9649 90.523 22.6917C90.5001 22.4595 90.5178 22.2121 90.5222 21.9788C90.5168 21.842 90.5525 21.7035 90.5334 21.5671C90.4898 21.1576 90.3496 21.0809 89.9729 21.2603C88.9362 21.74 87.9439 22.3002 86.8154 22.5368C85.3327 22.8285 83.8791 22.8174 82.6071 21.8533C81.576 21.0852 81.041 20.0371 80.8943 18.7543C80.7492 17.5125 80.8532 16.3294 81.3979 15.1974C82.3234 13.2963 83.8668 12.1111 85.8129 11.4035C86.9481 10.9885 88.1351 10.8455 89.3467 10.9758C89.402 10.9873 89.4567 10.9852 89.5115 10.983C89.5405 11.023 89.581 11.0077 89.6215 10.9924ZM89.5063 13.9718C88.436 13.9456 87.5063 14.0098 86.6132 14.3056C85.5449 14.6769 84.777 15.3653 84.5618 16.5117C84.4609 17.0777 84.4704 17.6669 84.4384 18.2439C84.3854 19.329 85.0352 19.4678 85.7491 19.4944C85.9139 19.5016 86.064 19.482 86.2277 19.4618C87.3863 19.2926 88.4667 18.8797 89.5307 18.3989C89.7053 18.3097 89.7837 18.2107 89.7619 18.0059C89.6878 17.1726 89.6142 16.3529 89.5675 15.5185C89.5343 15.0263 89.5274 14.5056 89.5063 13.9718Z" />
        <path d="M40.8337 20.2726C40.7378 20.3547 40.6419 20.4232 40.5597 20.5054C39.7789 21.4095 38.7378 21.7794 37.6009 21.889C36.8749 21.9575 36.1351 21.9848 35.4228 21.9164C34.0941 21.7794 33.2585 20.9849 32.9023 19.7383C32.2722 17.5603 33.1215 14.3549 35.916 12.9576C36.9844 12.4234 38.1351 12.2316 39.3132 12.1357C39.6556 12.1083 39.9981 12.1083 40.3406 12.0672C40.4227 12.0535 40.5597 11.9302 40.5597 11.8617C40.546 10.7796 40.2173 9.79329 39.4228 9.02618C38.8063 8.42345 38.0255 8.51934 37.3543 8.8755C36.7379 9.19056 36.1899 9.62891 35.6557 10.0536C34.9571 10.6289 34.9708 10.6426 34.2722 10.081C33.916 9.79329 33.5598 9.50562 33.2174 9.21796C32.8886 8.94399 32.8886 8.8755 33.1489 8.51934C33.8749 7.56045 34.7927 6.80704 35.8749 6.3002C37.3954 5.57419 38.9981 5.38241 40.6419 5.75227C42.1761 6.09473 43.0665 7.1495 43.5734 8.57413C43.9295 9.56042 44.0802 10.5878 44.0939 11.6152C44.1213 14.574 44.1213 17.5329 44.0939 20.4917C44.0939 20.6972 43.9295 20.9575 43.7651 21.0808C43.135 21.5465 42.3679 21.6561 41.6008 21.6972C41.2446 21.7109 41.1077 21.5328 41.094 21.1492C41.0803 20.5328 41.0803 20.5328 40.8337 20.2726ZM40.5734 16.9301C40.5871 16.9301 40.6008 16.9301 40.6145 16.9301C40.5597 16.3959 40.5186 15.848 40.4364 15.3137C40.3679 14.848 40.2447 14.7795 39.8063 14.8069C39.4776 14.8206 39.1488 14.848 38.82 14.9028C37.0392 15.2178 36.1214 16.2589 36.0119 18.026C35.9708 18.6698 36.1351 18.889 36.7653 18.9438C37.9159 19.0397 38.9844 18.7109 40.0529 18.3C40.4364 18.1493 40.6556 17.9301 40.5871 17.4918C40.546 17.2863 40.5734 17.1082 40.5734 16.9301Z" />
        <path d="M74.025 12.6562C73.0113 12.889 72.0935 13.0945 71.1757 13.3C69.4086 13.6972 67.6552 14.1082 65.8881 14.5055C65.5867 14.5739 65.4498 14.752 65.4635 15.026C65.5045 15.9575 65.5182 16.889 65.6415 17.8068C65.7785 18.8204 66.7648 19.4232 67.8744 19.2725C68.5456 19.1903 69.1209 18.8615 69.6963 18.5328C70.2305 18.2177 70.7784 17.889 71.3264 17.6013C71.8332 17.341 72.3264 17.3958 72.7236 17.8342C72.9839 18.1218 73.2305 18.4095 73.4633 18.7246C73.614 18.93 73.6003 19.1081 73.4359 19.341C71.9976 21.2998 70.0113 22.067 67.6415 21.9985C66.71 21.9711 65.7785 21.9711 64.9018 21.5738C63.7238 21.0259 62.9703 20.0944 62.5046 18.93C61.4635 16.2999 61.2169 13.6151 62.1895 10.9028C62.9977 8.64255 64.5457 7.14942 66.9429 6.64258C68.1894 6.38231 69.4634 6.25903 70.7373 6.56039C72.1209 6.87545 72.9154 7.82064 73.2579 9.10829C73.5729 10.259 73.7784 11.437 74.025 12.6562ZM65.3265 12.1493C67.1347 11.7932 68.8333 11.4644 70.5593 11.1357C70.4497 10.6151 70.3675 10.1905 70.2579 9.76581C70.1209 9.24527 69.8058 8.95761 69.2716 9.0124C68.6278 9.08089 67.9566 9.19048 67.3538 9.40966C66.1347 9.848 65.5867 10.8206 65.3265 12.1493Z" />
        <path d="M106.997 12.6563C106.079 12.8617 105.271 13.0535 104.463 13.2316C102.641 13.6426 100.833 14.0535 99.0108 14.4645C98.5177 14.574 98.3807 14.711 98.4081 15.2042C98.4629 16.122 98.504 17.0398 98.6272 17.9575C98.7094 18.6014 99.1889 18.9712 99.7916 19.163C100.531 19.3959 101.244 19.3 101.901 18.9438C102.435 18.6562 102.929 18.3274 103.463 18.0397C103.846 17.8343 104.23 17.6288 104.641 17.5055C104.86 17.437 105.202 17.4918 105.367 17.6288C105.764 17.9575 106.093 18.3685 106.435 18.7658C106.586 18.9438 106.559 19.1219 106.408 19.3274C105.134 21.0123 103.463 21.9438 101.34 21.9712C100.49 21.9849 99.6135 21.9986 98.7779 21.8616C97.2711 21.615 96.2574 20.6561 95.6136 19.3C94.6958 17.3685 94.3944 15.3138 94.5999 13.1905C94.7506 11.67 95.2026 10.2591 96.1204 9.0125C97.2026 7.54678 98.682 6.73857 100.477 6.54679C101.394 6.4509 102.339 6.39611 103.271 6.492C104.709 6.62898 105.696 7.47828 106.107 8.84812C106.49 10.081 106.696 11.3549 106.997 12.6563ZM103.518 11.1358C103.381 10.5878 103.285 10.1084 103.148 9.64263C103.011 9.17688 102.737 8.97141 102.257 8.9988C101.75 9.0262 101.23 9.09469 100.75 9.21798C99.5176 9.54674 98.7505 10.3686 98.3944 11.5878C98.3533 11.7385 98.3396 11.8892 98.3122 12.122C100.066 11.7933 101.764 11.4645 103.518 11.1358Z" />
        <path d="M55.4499 16.3958C55.6417 15.8068 55.8061 15.2177 56.039 14.6424C56.765 12.7931 57.0116 10.8205 57.5458 8.91647C57.9294 7.54663 57.6006 7.8206 58.9841 7.8206C59.669 7.8206 60.3403 7.90279 61.0252 7.94389C61.3539 7.95758 61.4224 8.13566 61.3266 8.42333C60.7786 10.2178 60.2992 12.0534 59.6827 13.8205C58.7513 16.4917 57.7376 19.1218 56.765 21.7793C56.6828 21.9984 56.5869 22.0669 56.354 22.0532C55.4362 22.0121 54.5322 21.9847 53.6144 21.9984C53.313 21.9984 53.2308 21.8751 53.176 21.6149C52.5185 18.5601 51.861 15.5191 51.1897 12.4644C50.7788 10.5877 50.3815 8.711 49.9706 6.84802C49.9432 6.71103 49.9295 6.58775 49.9021 6.40967C50.1076 6.42337 50.272 6.43706 50.4363 6.45076C51.5048 6.50556 52.5733 6.56035 53.6418 6.60145C53.8883 6.61514 53.9842 6.68364 54.0116 6.91651C54.313 9.09455 54.6143 11.2863 54.9294 13.4643C55.0664 14.4369 55.2171 15.3958 55.3678 16.3547C55.4089 16.3821 55.4226 16.3821 55.4499 16.3958Z" />
        <path d="M13.0648 6.40967C12.147 6.46446 11.2566 6.50556 10.3525 6.54665C9.99638 6.56035 9.64022 6.58775 9.29777 6.58775C9.09229 6.58775 9.0101 6.65624 8.9827 6.87541C8.72243 8.76579 8.44847 10.6562 8.1882 12.5465C8.01012 13.8205 7.81834 15.0807 7.57177 16.3684C7.43479 15.9711 7.3115 15.5602 7.16082 15.1766C6.39371 13.2041 5.99646 11.1219 5.51701 9.06716C5.43482 8.711 5.32523 8.36854 5.20195 8.02608C5.17455 7.93019 5.03757 7.8069 4.95538 7.8069C3.9417 7.8343 2.92802 7.88909 1.92804 7.93019C1.68147 7.94389 1.61297 8.08087 1.68147 8.30005C1.94174 9.24523 2.13351 10.2041 2.44858 11.1219C3.55814 14.3136 4.70881 17.4917 5.85947 20.6834C5.99646 21.0532 6.13344 21.4231 6.27042 21.8067C6.32522 21.9847 6.40741 22.0532 6.61288 22.0532C7.55807 22.0258 8.50326 21.9984 9.44845 21.9984C9.68132 21.9984 9.77721 21.9162 9.8183 21.7108C10.1608 20.0396 10.5169 18.3821 10.8731 16.7109C11.2977 14.7383 11.7498 12.7657 12.1881 10.7932C12.4758 9.40961 12.7635 8.02608 13.0511 6.62884C13.0648 6.57405 13.0648 6.50556 13.0648 6.40967Z" />
        <path d="M16.329 8.20791C16.7536 8.20791 17.1783 8.20791 17.5892 8.20791C17.9043 8.20791 18.0413 8.34489 18.0139 8.67365C17.8769 10.6325 17.7399 12.5914 17.5892 14.564C17.4522 16.3447 17.3015 18.1255 17.1509 19.9063C17.0961 20.6323 17.0139 21.3584 16.9454 22.0844C16.918 22.3446 16.8084 22.5364 16.5344 22.5364C15.7399 22.5364 14.9454 22.509 14.1509 22.509C13.9044 22.509 13.8359 22.372 13.8496 22.1666C13.8633 21.1255 13.8633 20.0981 13.9044 19.057C13.9728 17.5639 14.0413 16.0845 14.1509 14.5914C14.3016 12.5914 14.5071 10.5914 14.6852 8.57776C14.7126 8.249 14.8495 8.09832 15.2194 8.15311C15.5756 8.20791 15.9454 8.19421 16.3153 8.22161C16.329 8.19421 16.329 8.19421 16.329 8.20791Z" />
        <path d="M14.6736 3.9476C14.6325 3.00241 14.9202 2.18051 15.6462 1.53668C16.2215 1.01614 16.9065 0.769573 17.6599 1.04354C18.4133 1.31751 18.7831 1.93393 18.9475 2.68735C19.0982 3.44076 18.9612 4.16677 18.6188 4.83799C18.1119 5.86537 17.1256 6.18043 15.9476 5.74208C14.975 5.38593 14.701 5.00237 14.6736 3.9476Z" />
        <path d="M78.6184 7.29931C78.6879 7.95444 78.7448 8.63754 78.8172 9.36111C78.9094 9.27486 78.9761 9.23084 79.0278 9.16005C80.5308 7.44981 82.7494 6.36679 86.271 6.21471C86.5173 6.20407 86.6446 6.29455 86.6945 6.49806C86.8538 7.32757 86.9862 8.17194 87.1317 9.00203C85.2729 9.23472 84.2931 9.48112 83.7111 9.65707C81.8307 10.2456 80.2839 11.2585 79.4878 13.1438C79.2395 13.7441 79.0775 14.4367 79.0648 15.0954C79.007 17.2505 79.0314 19.4021 79.0415 21.5406C79.0402 21.8286 78.948 21.9149 78.6742 21.9267C77.9894 21.9426 77.3057 21.9858 76.6208 22.0017C76.0186 22.0277 75.7046 21.7396 75.6643 21.1243C75.5979 20.2222 75.5184 19.3345 75.4931 18.4306C75.4088 15.8429 75.325 13.2688 75.2954 10.6786C75.285 9.80158 75.4388 8.91743 75.5794 8.04755C75.6647 7.48171 75.8123 7.40678 76.3871 7.38196C77.1267 7.36373 77.852 7.3324 78.6184 7.29931Z" />
      </g>
      <g fill={theme.colors.white}>
        <path d="M29.1483 37.3858C30.3083 37.3858 31.1243 36.8178 31.4843 35.7298L30.3483 35.2658C30.1403 35.9458 29.7003 36.3138 29.1243 36.3138C28.3803 36.3138 27.8443 35.6418 27.8443 34.5138C27.8443 33.4098 28.3483 32.7698 29.1403 32.7698C29.7163 32.7698 30.1803 33.1058 30.3243 33.7938L31.4763 33.3458C31.1003 32.2578 30.3003 31.6978 29.1483 31.6978C27.3563 31.6978 26.6443 33.0738 26.6443 34.5458C26.6443 36.0178 27.3643 37.3858 29.1483 37.3858Z" />
        <path d="M35.6952 37.3858C37.3352 37.3858 38.3752 36.2818 38.3752 34.5458C38.3752 32.8098 37.3352 31.7058 35.6952 31.7058C34.0872 31.7058 33.0552 32.8098 33.0552 34.5458C33.0552 36.2818 34.0872 37.3858 35.6952 37.3858ZM35.6952 36.3218C34.8872 36.3218 34.3672 35.6338 34.3672 34.5458C34.3672 33.4578 34.8872 32.7698 35.6952 32.7698C36.5352 32.7698 37.0632 33.4578 37.0632 34.5458C37.0632 35.6338 36.5352 36.3218 35.6952 36.3218Z" />
        <path d="M42.1075 37.3698C43.2915 37.3698 44.1075 36.6818 44.1075 35.6018C44.1075 34.4338 43.1315 34.0898 42.3395 33.8258C41.8035 33.6418 41.3555 33.4978 41.3555 33.1458C41.3555 32.8738 41.6195 32.6978 41.9795 32.6978C42.3235 32.6978 42.7635 32.8738 43.1315 33.2738L43.8035 32.5458C43.2435 31.9298 42.6435 31.7058 42.0035 31.7058C40.9875 31.7058 40.1875 32.2578 40.1875 33.2658C40.1875 34.3218 41.0595 34.6178 41.8115 34.8578C42.4035 35.0578 42.9395 35.2258 42.9395 35.6898C42.9395 36.0498 42.6275 36.2978 42.1315 36.2978C41.6355 36.2978 41.1955 36.0418 41.1715 35.5458H39.9715C39.9795 36.6018 40.8755 37.3698 42.1075 37.3698Z" />
        <path d="M46.9509 37.3058H48.1589V32.8578H49.8148V31.7858H45.3028V32.8578H46.9509V37.3058Z" />
        <path d="M54.5122 37.3058H55.8162L53.8882 31.7858H52.3042L50.3842 37.3058H51.6962L52.0962 36.0738H54.1202L54.5122 37.3058ZM52.4082 35.1058L53.0082 33.2658C53.0482 33.1298 53.0962 33.0098 53.0962 32.8738H53.1282C53.1282 33.0098 53.1762 33.1298 53.2242 33.2658L53.8082 35.1058H52.4082Z" />
        <path d="M60.6184 37.3138H61.8344V35.0818H62.5704L63.6504 37.3138H65.0584L63.7544 34.9058C64.3864 34.6418 64.7704 34.1538 64.7704 33.4178C64.7704 32.3938 63.9864 31.7858 62.9304 31.7858H60.6184V37.3138ZM61.8424 34.0578V32.8818H62.9224C63.3384 32.8818 63.5944 33.1058 63.5944 33.4658C63.5944 33.8098 63.3624 34.0338 62.9624 34.0578H61.8424Z" />
        <path d="M66.8652 37.3058H68.0812V31.7858H66.8652V37.3058Z" />
        <path d="M72.592 37.3858C73.752 37.3858 74.568 36.8178 74.928 35.7298L73.792 35.2658C73.584 35.9458 73.144 36.3138 72.568 36.3138C71.824 36.3138 71.288 35.6418 71.288 34.5138C71.288 33.4098 71.792 32.7698 72.584 32.7698C73.16 32.7698 73.624 33.1058 73.768 33.7938L74.92 33.3458C74.544 32.2578 73.744 31.6978 72.592 31.6978C70.8 31.6978 70.088 33.0738 70.088 34.5458C70.088 36.0178 70.808 37.3858 72.592 37.3858Z" />
        <path d="M80.2856 37.3058H81.5896L79.6616 31.7858H78.0776L76.1576 37.3058H77.4696L77.8696 36.0738H79.8936L80.2856 37.3058ZM78.1816 35.1058L78.7816 33.2658C78.8216 33.1298 78.8696 33.0098 78.8696 32.8738H78.9016C78.9016 33.0098 78.9496 33.1298 78.9976 33.2658L79.5816 35.1058H78.1816Z" />
      </g>
    </svg>
  )
}
