/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'

import CoverImage from '../assets/cover-image.png'

type OGMetaTags = Partial<
  Record<
    | 'og:title'
    | 'og:description'
    | 'og:type'
    | 'og:image'
    | 'og:image:alt'
    | 'og:url',
    string
  >
>
interface SEOProps {
  description?: string
  title?: string
  og?: OGMetaTags
}

export const SEO: React.FC<SEOProps> = ({
  children,
  description,
  og,
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            description
            author
            siteUrl: url
            titleTemplate
          }
        }
      }
    `,
  )

  const pageTitle = title || site.siteMetadata.defaultTitle
  const metaDescription = description || site.siteMetadata.description

  const ogMetaTags = Object.assign<OGMetaTags, OGMetaTags>(
    {
      'og:type': 'website',
      'og:title': pageTitle,
      'og:description': description || site.siteMetadata.description,
      'og:url': site.siteMetadata.siteUrl,
      'og:image': CoverImage,
      'og:image:alt': 'Coucher de soleil à la plage par catherinelavoie.com',
    },
    og,
  )

  return (
    <Helmet
      titleTemplate={site.siteMetadata.titleTemplate}
      defaultTitle={site.siteMetadata.defaultTitle}
      title={title}
    >
      {/* Google tag (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-4SY4VVEQNV"
      ></script>
      <script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-4SY4VVEQNV');
`}</script>

      <meta name="description" content={metaDescription} />
      {Object.entries(ogMetaTags).map(([property, content]) => (
        <meta key={`meta-${property}`} property={property} content={content} />
      ))}
      {children}
    </Helmet>
  )
}
