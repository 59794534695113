export const palettes = {
  green: {
    lightest: '#F3F5F4',
    lighter: '#DBE2DD',
    light: '#C3CFC6',
    base: '#6F8A77',
    dark: '#485A4E',
    darker: '#354239',
    darkest: '#2A352E',
  },
}

export const palettesDark = {
  green: {
    darkest: '#F3F5F4',
    darker: '#DBE2DD',
    dark: '#C3CFC6',
    base: '#6F8A77',
    light: '#485A4E',
    lighter: '#354239',
    lightest: '#2A352E',
  },
}

export const colors = {
  blazeOrange: '#ff5c00' as '#ff5c00',
  blazeOrangeDark: '#FF6A29' as '#FF6A29',
}

export type ThemeColorPalette = typeof palettes
