import { Maybe } from '../../../types/graphql-types'

export const breakpoints = {
  verySmall: 400 as 400,
  small: 640 as 640,
  medium: 896 as 896,
  large: 1152 as 1152,
  veryLarge: 1408 as 1408,
}

export function generateMediaQueries() {
  return Object.entries(breakpoints).reduce(
    (memo, [key, value]) => ({
      ...memo,
      [key]: `screen and (min-width: ${value}px)`,
    }),
    {} as MediaQueries,
  )
}

export type Breakpoints = typeof breakpoints
export type BreakpointType = keyof Breakpoints
export type MediaQueries = Record<keyof Breakpoints, string>

export type FluidSize = [
  Maybe<number>,
  Maybe<number>?,
  Maybe<number>?,
  Maybe<number>?,
  Maybe<number>?,
  Maybe<number>?,
]
