import * as React from 'react'

import {
  ResponsiveHookProps,
  useResponsive,
} from '../../theme/helpers/mediaQuery'
import { Heading, HeadingProps } from './Heading'
import { WidowlessText, WidowlessTextProps } from './WidowlessText'

interface WidowlessTitleProps
  extends HeadingProps,
    Pick<WidowlessTextProps, 'wordCount'>,
    Partial<ResponsiveHookProps> {
  children: string
}

export const WidowlessTitle: React.FC<WidowlessTitleProps> = function({
  breakpoint,
  children,
  wordCount,
  ...headingProps
}) {
  const enableWidowless = useResponsive({ breakpoint })
  return (
    <WidowlessText
      text={children}
      wordCount={wordCount}
      disable={!enableWidowless}
    >
      {(output) => <Heading {...headingProps}>{output}</Heading>}
    </WidowlessText>
  )
}
