import { em, timingFunctions, transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { remUnit } from '../../../theme/helpers/grid'
import { configureFontSize } from '../../../theme/helpers/typography'
import { getColor, mediaQuery } from '../../../theme/selectors'

function configureContent(
  fontSize: number,
  lineHeight: number,
  margin: number,
) {
  return css`
    ${configureFontSize(fontSize, lineHeight)}
    > * + * {
      margin-block-start: ${em(margin, fontSize)};
    }

    > ol,
    ul {
      list-style: revert;
      padding-inline-start: max(${remUnit(5)}, 4vw);
      border-inline-start: 2px solid
        ${(props) => transparentize(0.8, getColor('muted')(props))};
    }

    li + li {
      margin-block-start: ${em(4, fontSize)};
    }
  `
}

export const Content = styled.div`
  ${configureContent(18, 28, 32)}

  a {
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      width: ${`calc(100% + ${remUnit(0.5)})`};
      height: 100%;
      left: ${remUnit(-0.25)};
      background: ${(props) => transparentize(0.75, getColor('accent')(props))};
      transition: 0.15s ${timingFunctions('easeInOutCirc')};
      transform: scaleY(0.25);
      transform-origin: bottom;
    }

    &:hover::after {
      background: ${(props) => transparentize(0.85, getColor('accent')(props))};
      transform: translateY(1px) scaleY(1);
    }
  }

  ${mediaQuery('medium')`
    ${configureContent(21, 32, 32)}
  `}
`
